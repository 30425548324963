import { createContext, useCallback, useContext, useState, useEffect } from 'react';
import { Snackbar } from '@mui/material';

const SnackbarContext = createContext();
export const SnackbarConsumer = SnackbarContext.Consumer;
/**
 * Snackbar context provider
 *
 * Provides a way to display notifications as snackbars
 * - only one visible at a time
 * - expects snack.message to be an Alert, but could just be text
 */
export function SnackbarProvider({ children }) {
  const [snackPack, setSnackPack] = useState([]);

  const [snack, setSnack] = useState(undefined);

  useEffect(() => {
    if (snackPack.length && !snack) {
      // Set a new snack when we don't have an active one
      setSnack(snackPack[0]);
      setSnackPack((prev) => prev.slice(1));
    } else if (snackPack.length && snack) {
      // Close an active snack when a new one is added
      setSnack(undefined);
    }
  }, [snackPack, snack]);

  const enqueueSnackbar = useCallback(
    (message, options) =>
      setSnackPack((prev) => [...prev, { message, key: new Date().getTime(), ...options }]),
    []
  );

  const handleClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack(undefined);
  }, []);

  return (
    <SnackbarContext.Provider
      value={{
        enqueueSnackbar,
        snack,
        Snackbar: () => (
          <Snackbar
            autoHideDuration={10000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            {...snack}
            open={!!snack}
            onClose={handleClose}
          >
            {snack?.message}
          </Snackbar>
        )
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
}

/**
 * Wrapper around a context hook for clearer naming
 */
export function useSnackbar() {
  return useContext(SnackbarContext);
}
