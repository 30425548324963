import React, { Suspense, lazy } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { NZPostTheme } from 'style/theme';
import AuthProvider from 'components/AuthProvider';
import Page from 'components/Page/Page';
import { withAuthPoll } from 'components/withAuthPoll';
import BackToNZPost from 'routes/BackToNZPost';

// Lazy load so that fetches in Home don't run on Verify page
// https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
const Home = lazy(() => import('./routes/Home'));
const Verify = lazy(() => import('./routes/Verify'));
const ChangeEmail = lazy(() => import('./routes/ChangeEmail'));
const RealMe = lazy(() => import('./routes/RealMe'));
const ChangePassword = lazy(() => import('./routes/ChangePassword'));
const Logout = lazy(() => import('./routes/Logout'));
const LogoutProcess = lazy(() => import('./routes/Logout/Process'));
const Test = lazy(() => import('./routes/Logout/test'));
const Cookie = lazy(() => import('./routes/Cookie'));

const ProtectedRoute = ({ component, ...args }) => (
  <Route component={withAuthenticationRequired(withAuthPoll(component))} {...args} />
);

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <CookiesProvider>
          <Switch>
            <Route exact path="/cookie">
              <Cookie />
            </Route>
            <Route path="/">
              <ThemeProvider theme={NZPostTheme}>
                <CssBaseline />
                <AuthProvider>
                  <Page>
                    <Switch>
                      <ProtectedRoute exact path="/" component={Home} />
                      <ProtectedRoute exact path="/change-email" component={ChangeEmail} />
                      <ProtectedRoute exact path="/change-password" component={ChangePassword} />
                      <ProtectedRoute exact path="/verify" component={Verify} />
                      <Route exact path="/backtonzpost">
                        <BackToNZPost />
                      </Route>
                      <Route exact path="/realme">
                        <RealMe />
                      </Route>
                      <Route exact path="/logout">
                        <Logout />
                      </Route>
                      <Route exact path="/logout/process">
                        <LogoutProcess />
                      </Route>
                      <Route exact path="/logout/test">
                        <Test />
                      </Route>
                      <Route path="*">
                        <Redirect to="/" />
                      </Route>
                    </Switch>
                  </Page>
                </AuthProvider>
              </ThemeProvider>
            </Route>
          </Switch>
        </CookiesProvider>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
