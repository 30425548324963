import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const withAuthPoll = (Component) => {
  return function WithAuthPoll(props) {
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
      const checkAuth = () => {
        getAccessTokenSilently();
      };

      const authPoll = setInterval(checkAuth, process.env.REACT_APP_AUTH_POLL_INTERVAL);
      window.addEventListener('focus', checkAuth);
      // cleanup
      return () => {
        clearInterval(authPoll);
        window.removeEventListener('focus', checkAuth);
      };
    }, [getAccessTokenSilently]);

    return <Component {...props} />;
  };
};
