import { createTheme } from '@mui/material/styles';
import variables, {
  fontFamily,
  primary,
  primaryText,
  secondary,
  secondaryText,
  error,
  info,
  warning,
  success
} from './variables.module.scss';

const breakpoints = Object.fromEntries(
  Object.entries(variables)
    .filter(([key]) => key.includes('breakpoint-'))
    .map(([key, value]) => [key.replace('breakpoint-', ''), +value])
);
const shadows = Object.entries(variables)
  .filter(([key]) => key.includes('shadow-'))
  .map(([key, value]) => value);

export const NZPostTheme = createTheme({
  typography: {
    fontFamily: fontFamily
  },
  breakpoints: {
    values: breakpoints
  },
  shadows,
  palette: {
    primary: {
      main: primary,
      contrastText: primaryText
    },
    secondary: {
      main: secondary,
      contrastText: secondaryText
    },
    error: { main: error },
    info: { main: info },
    warning: { main: warning },
    success: { main: success },
    tonalOffset: 0
  },
  components: {
    MuiAppBar: { defaultProps: { elevation: 1 } },
    MuiTextField: {
      defaultProps: { variant: 'outlined', color: 'info', fullWidth: true, margin: 'dense' }
    },
    MuiButton: {
      defaultProps: { size: 'medium', color: 'secondary', disableElevation: true }
    }
  }
});
