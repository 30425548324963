import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

/**
 * Auth0Provider with redirect helper
 */
export default function AuthProvider({ children }) {
  const history = useHistory();
  /**
   * A function that routes the user to the right place after login
   */
  const onRedirectCallback = useCallback(
    (appState) => {
      if (appState?.returnTo) {
        window.location.replace(appState?.returnTo);
      } else {
        history.push(appState?.targetUrl || window.location.pathname);
      }
    },
    [history]
  );

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
}
