import React from 'react';
import { SnackbarProvider, SnackbarConsumer } from 'components/Snackbar';
import Header from './Header';
import Footer from './Footer';

export default function Page({ children }) {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <SnackbarConsumer>
        {({ Snackbar }) => (
          <>
            <Header />
            <main style={{ minHeight: '50vh' }}>{children}</main>
            <Snackbar />
            <Footer />
          </>
        )}
      </SnackbarConsumer>
    </SnackbarProvider>
  );
}
