import React from 'react';
import { Container } from '@mui/material';
import logo from 'assets/nz-post-logo.svg';

export default function Footer() {
  return (
    <footer>
      <Container maxWidth="xl">
        <div className="footer-container">
          <div className="footer-container">
            <a className="footer-link footer-item" href="https://www.nzpost.co.nz/">
              Help & Support
            </a>
            <span className="footer-spacer">|</span>
            <a className="footer-link footer-item" href="https://www.nzpost.co.nz/sitemap">
              Site Map
            </a>
          </div>
          <div className="footer-container">
            <a
              className="footer-link footer-item"
              href="https://www.nzpost.co.nz/about-us/who-we-are/terms-of-business/website-terms-conditions"
            >
              Terms & Conditions
            </a>
            <span className="footer-spacer">|</span>
            <span className="footer-copyright footer-item">© NZ Post {new Date().getFullYear()}</span>
            <img className="footer-logo" src={logo} alt="New Zealand Post" />
          </div>
        </div>
      </Container>
    </footer>
  );
}
