import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Button, Stack } from '@mui/material';

export default function BackToNZPost() {
  const history = useHistory();
  const [redirectLinks, setRedirectLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const IOS_REDIRECT_LINK_URL = process.env.REACT_APP_IOS_REDIRECT_LINK_URL;
    const ANDROID_REDIRECT_LINK_URL = process.env.REACT_APP_ANDROID_REDIRECT_LINK_URL;
    const WEBSITE_REDIRECT_LINK_URL = process.env.REACT_APP_WEBSITE_REDIRECT_LINK_URL;

    if (userAgent.includes('android')) {
      // Display links for Android devices
      setRedirectLinks([
        { url: WEBSITE_REDIRECT_LINK_URL, label: 'Back to NZ Post Website', cta: 'primary' },
        { url: ANDROID_REDIRECT_LINK_URL, label: 'Back to Android App', cta: 'secondary' }
      ]);
    } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
      // Display links for iOS devices
      setRedirectLinks([
        { url: WEBSITE_REDIRECT_LINK_URL, label: 'Back to NZ Post Website', cta: 'primary' },
        { url: IOS_REDIRECT_LINK_URL, label: 'Back to iOS App', cta: 'secondary' }
      ]);
    } else {
      // Redirect for desktop devices
      history.push(WEBSITE_REDIRECT_LINK_URL);
    }
    // Set isLoading to false once the check is completed
    setIsLoading(false);
  }, []);

  return isLoading ? null : (
    <Container maxWidth="xl">
      <h4>Back to NZ Post</h4>
      <p>You profile update was successful</p>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
        {redirectLinks.map((link, index) => {
          return (
            <Button
              key={index}
              variant={link.cta === 'primary' ? 'contained' : 'outlined'}
              color="secondary"
              onClick={() => window.open(link.url, '_self')}
            >
              {link.label}
            </Button>
          );
        })}
      </Stack>
    </Container>
  );
}
