import React from 'react';
import { AppBar, Container, Button, Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import logo from 'assets/nz-post-logo.svg';

export default function Header() {
  const { isAuthenticated } = useAuth0();
  const { replace } = useHistory();
  return (
    <AppBar position="relative">
      <Container maxWidth="xl">
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <a href="https://www.nzpost.co.nz" title="New Zealand Post">
              <img
                className="logo"
                src={logo}
                alt="New Zealand Post"
                style={{ display: 'block' }}
              />
            </a>
          </Grid>
          {isAuthenticated && (
            <Grid item>
              <Button variant="text" onClick={() => replace('/logout')}>
                Sign out
              </Button>
            </Grid>
          )}
        </Grid>
      </Container>
    </AppBar>
  );
}
