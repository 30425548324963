import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './style/import.scss';

// `scope` needs to be passed to createAuth0Client through here.
// Otherwise, just passing it in an option to loginWithRedirect won't work,
// in which case isAuthenticated never becomes true and repeats login endlessly.
// https://github.com/auth0/auth0-spa-js/issues/321#issuecomment-614014028
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
